/* .bytPlayerWrap {
	position: relative; 
	text-align: center;
	height: 39vw;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 75% 39vw;
}

.bytPlayerWrap iframe {
	height: 34vw;
	width: 75%;
	object-fit: fill; 
	aspect-ratio: 16 / 9;
	max-width: 1000px;
} */


.bytPlayerWrap {
	width: 80%;
	height: 0;
	padding-bottom: 45.00%;
	/* 16:9 aspect ratio */
	position: relative;
	margin: 0 auto;
}

.bytPlayerWrap iframe {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	object-fit: fill;
}